<template>
    <div
        v-loading="loading"
        class="event-service-participants"
    >
        <div class="event-service-participants-header">
            <div class="event-service-participants-header__title">
                {{ title }}
            </div>
            <a
                class="event-service-participants-header__icon"
                @click="_onPrintBtnClick"
            ><i class="fas fa-print" /></a>
        </div>
        <div class="event-service-participants-body">
            <div
                v-for="(value, key) in list"
                :key="key"
                class="event-service-participants-list-item"
            >
                <div class="event-service-participants-list-item__date">
                    {{ key }}
                </div>
                <div
                    v-for="(item, itemKey) in value"
                    :key="itemKey"
                    class="event-service-participants-list-item-participants"
                >
                    <div class="event-service-participants-list-item-participants__time">
                        {{ item.time }}
                    </div>
                    <div class="event-service-participants-list-item-participants__item">
                        <div
                            v-for="(participant, index) in item.participants"
                            :key="participant.id"
                            class="event-service-participant-data"
                        >
                            <div class="event-service-participant-data__fullname">
                                {{ index+1 }}. {{ participant.fullname }}
                                <div class="event-service-participant-data__plan">
                                    {{ participant.plan }}
                                </div>
                            </div>
                            <div class="event-service-participant-data__phone">
                                {{ participant.phone }}
                                {{ participant.phone2 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import breadcrumbs from '@/mixins/breadcrumbs';
import { ROUTES } from '@/enums';
import { compose, map, groupBy } from 'lodash/fp';
import JSONStorage from '@/utils/storage';

export default {
    name: 'EventServiceParticipants',

    mixins: [
        breadcrumbs
    ],

    data() {
        return {
            loading: false,
            list: [],
            title: null,
            eventTitle: null
        };
    },

    computed: {
        $breadcrumbs() {
            const bc = [
                ['События', ROUTES.EVENT.LIST]
            ];

            if (this.eventTitle) {
                bc.push([this.eventTitle, ROUTES.EVENT.LIST, { eventId: this.eventId }]);
            }

            bc.push(['Услуги', ROUTES.EVENT.CARD.SERVICE.LIST, { eventId: this.eventId }]);

            if (this.title) {
                bc.push([this.title]);
            }

            return bc;
        },

        serviceId() {
            return this.$route.params.serviceId;
        },

        eventId() {
            return this.$route.params.eventId;
        }
    },

    mounted() {
        this._getScheduledParticipants();
        this.$echo.private(`Service.${this.serviceId}`)
            .listen('ParticipantScheduledEcho', () => {
                this._getScheduledParticipants();
            });
    },

    destroyed() {
        this.$echo.leave(`Service.${this.serviceId}`);
    },

    methods: {
        ...mapActions('event/service/schedule', [
            'getRecordedParticipants'
        ]),

        prepareList(list) {
            return compose(
                groupBy(x => x.date),
                map(x => {
                    return {
                        date: x.attributes.date,
                        time: x.attributes.time,
                        participants: this.preparePaticipants(x.relationships.scheduleparticipants)
                    };
                })
            )(list);
        },

        preparePaticipants(participants) {
            return participants.map(participant => {
                return {
                    id: participant.id,
                    fullname: participant.attributes.fullname,
                    phone: participant.attributes.phone,
                    phone2: participant.attributes.phone2,
                    plan: participant.attributes.plan
                };
            });
        },

        async _getScheduledParticipants() {
            this.loading = true;
            let response = await this.getRecordedParticipants({
                eventId: this.eventId,
                serviceId: this.serviceId
            });
            if (response.success) {
                this.list = this.prepareList(response.data);
                this.title = _.get(response, 'meta.title', 'Нет данных');
                this.eventTitle = _.get(response, 'meta.event.title', 'Нет данных');
            }
            this.loading = false;
        },

        _onPrintBtnClick() {
            let printData = {
                title: this.title,
                list: this.list
            };
            JSONStorage.put('ServiceScheduledParticipants', printData);
            let printRoute = this.$router.resolve({
                name: ROUTES.PRINT.SERVICE.SCHEDULED_LIST
            });
            window.open(printRoute.href, '_blank');
        }
    }

};
</script>

<style lang="scss">
.event-service-participants {
    .event-service-participants-body {
        max-width: 500px;
    }

    .event-service-participant-data {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding: 17px 0;
        border-bottom: 1px solid #ebeef5;

        &__plan {
            padding-top: 10px;
            font-size: 12px;
        }

    }

    .event-service-participants-list-item {
        padding: 16px 0;

        &__date {
            font-size: 24px;
            margin-bottom: 15px;
        }
    }

    .event-service-participants-list-item-participants {
        display: flex;
        border-top: 1px solid #000;

        &__time {
            flex: 1;
            font-size: 18px;
            padding-top: 16px;
        }

        &__item {
            flex: 3;
            display: flex;
            flex-direction: column;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 600px;

        &__title {
            font-size: 36px;
        }

        &__icon {
            font-size: 36px;
            color: #1871f8;
            cursor: pointer;
        }
    }

}

</style>
